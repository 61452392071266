export const locale = {
    lang: 'es',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'DASHBOARDS'  : 'Dashboards',
            'CALENDAR'    : 'Calendar',
            'ECOMMERCE'   : 'E-Commerce',
            'ACADEMY'     : 'Academy',
            //
            'ADMIN'       : 'Admin',
            'NOMINA'      : 'Nomina',
            'PAGO'        : 'Pago',
            'REPORTE'     : 'Reporte',
            'BANCO'       : 'Banco',
            'BALANCE'       : 'Balance',
            //
            'CHAT'        : 'Chat',
            'CONTACTS'    : 'Contacts',
            'DASH': {
                'RESUMEN': 'Resumen',
            },
            'NOM': {
                'CARGA': 'Cargar Nomina',
                'LISTA': 'Lista de Nominas',
                'APROBAR': 'Aprobar Nominas'
            },
            'REP': {
                'TRANSACCION': 'Reporte de Transacciones',
                'OPERATIVO': 'Reporte Operativo',
                'DESCARGABLE':'Reporte Descargable'
            },
            'BAN': {
                'ENVIO': 'Envio al banco',
                'RESPUESTA': 'Respuesta del banco',
                'RESPUESTA_MANUAL': 'Respuesta del banco - Manual',
                'CARGAR_CONSTANCIA': 'Cargar constancia',
		        'ACTUALIZAR_ESTADO': 'Actualizar estado'
            },
            'BALAN': {
                'REGISTRO': 'Registro de Movimientos',
                'CONSULTA': 'Consulta de balance',
                'DETAIL': 'Consulta de Movimientos'
            },
            'ADM': {
                'USUARIOS': 'Lista de Usuarios',
                'COMERCIOS': 'Lista de Comercios',
                'ROLES': 'Lista de Roles',
                'GRUPO_COMERCIOS': 'Lista de Grupos Comercios',
                'COMERCIO': 'Datos del comercio',
                'TARIFAS': 'Tarifas'
            }
        },

        'ELEM_GENERIC': {
            'BUSCAR': 'Buscar',
            'GUARDAR': 'Guardar',
            'GENERAR': 'Generar',
            'BUSCAR_X_ID_NOMINA': 'ID Nómina',
            'MONEDA': 'Moneda',
            'BANCO': 'Banco',
            'FECHA_INI': 'Fecha Inicio',
            'FECHA_FIN': 'Fecha Fin',
            'FECHA': 'Fecha',
            "TOTAL":'Total',
            'ESTADO': 'Estado',
            'DIA_DE_HOY': 'Día de hoy',
            'DIA_DE_HOY_2': 'Al día de hoy',
            'DESCARGAR_EXCEL' : 'Descargar Excel',
            'DESCARGAR_CSV': 'Descargar Csv',
            'PERFIL' : 'Perfil',
            'ESPERE' : 'Espere por favor  ...',
	        'ACCION': 'Acción',
            'TRANSFERENCIA_BANCARIA': 'Transferencia Bancaria'
        },

        'MENSAJES_MODAL': {
            'NOMINA_VALIDA': {
                '1': 'Id de Nomina Nro: ',
                '2': 'Validadas: ',
                '3': 'Errores: ',
                'INS_SALDO': 'El monto de esta nómina supera el saldo de su comercio, por favor revisar su balance y ponerse en contacto con el equipo de operaciones para la gestión de transferencia de fondos',
            },
            'NOMINA_CARGA' : {
                '1': 'Nro de proceso en ejecucion: ',
                '2': 'Espere su validación ...',
            }
        },

        'PERFIL': {
            'TITULO': 'Perfil',
            'DATOS_USUARIO': 'Datos de Usuario',
            'APELLIDO': 'Apellidos',
            'NOMBRE': 'Nombre',
            'CAMBIO_CONTRASENIA': 'Cambio de Contraseña',
            'CONTRASENIA_ACTUAL': 'Contraseña Actual',
            'NUEVA_CONTRASENIA': 'Nueva Contraseña',
            'CAMBIAR_CONTRASENIA': 'Cambiar Contraseña',
        },

        'DASHBBOARD': {
            'CANT_TOTAL_TRANS': 'Cantidad Total de Transacciones',
            'MONTO_TOTAL_TRANS'  : 'Monto Total de Transacciones',
            'BALANCE_ACUMULADO'    : 'Balance Acumulado',
            'MONTO_TOTAL_TRANS_BANCO_EXITO'   : 'Monto Total de Transacciones Banco Éxito',
            'MON_TOT_TRANS_BANCO_EXITO' : {
                'MONTO_INGRESO':'Monto Ingreso',
                'MONTO_EGRESO':'Monto Egreso',
                'MONTO_SALDO':'Saldo'
            }
        },
        'NOMIMA': {
            'CARGA': {
                'TITULO': 'Cargar Nómina',
                'MONEDA': 'Moneda',
                'FORMATO': 'Formato de Nómina',
                'ELIJA' : 'Elija un archivo',
                'CARGAR' : 'Cargar',
                'CARGANDO' : 'Cargando ...'
            },
            'LISTA': {
                'TITULO': 'Nóminas',
                'SUBTITULO': 'Nóminas',
                'TABLE_ID': 'ID',
                'TABLE_ARCHIVO' : 'Archivo',
                'TABLE_PROCESO' : 'Proceso',
                'TABLE_SUBIDO_POR' : 'Subido Por',
                'TABLE_ESTADO' : 'Estado',
                'TABLE_MONEDA' : 'Moneda',
                'TABLE_TOTAL' : 'Total Monto',
                'TABLE_TOTAL_#' : 'Total #',
                'TABLE_FECHA_CARGA' : 'Fecha Carga',
                'TABLE_ULT_ACTUALIZACION' : 'Ultima Actualización',
                'TABLE_GRUPO' : 'Grupo',
                'TABLE_PAIS' : 'País',
                'TABLE_ACCIONES' : 'Acciones',
                'ACCION_ORIGINAL' : 'Original',
                'ACCION_VALIDADO' : 'Validado',
                'ACCION_ERROR' : 'Error',
                'ACCION_GENERAL' : 'Consolidado',
                'NOMINA_ORIGINAL' : {
                    'TITULO': 'Detalles de Nómina',
                    'SUBTITULO': 'Listado de Transacciones',
                    'TABLE_TRANS_NRO': 'Nro Transaccion',
                    'TABLE_ORDEN_COMPRA' : 'Orden de Compra',
                    'TABLE_NOTA_CREDITO' : 'Nota de Credito',
                    'TABLE_CUENTA_DEST' : 'Cuenta de Destino',
                    'TABLE_TIPO_CUENTA' : 'Tipo de Cuenta',
                    'TABLE_BANCO' : 'Banco',
                    'TABLE_MONTO_USD' : 'Monto en Dólares',
                    'TABLE_IDTYPE_CLIENTE' : 'Tipo Documento',
                    'TABLE_SHOPPER_ID' : 'ID Cliente',
                    'TABLE_ID_CLIENTE' : 'Documento',
                    'TABLE_NOMBRE' : 'Cliente',
                    'TABLE_DEPARTAMENTO' : 'Departmento',
                    'TABLE_CCI' : 'Cuenta CCI ',
                    'TABLE_MONEDA' : 'Moneda',
                    'TABLE_MONTO' : 'Monto',
                    'TABLE_CANAL' : 'Canal',
                    'TABLE_CORREO' : 'Correo',
                    'TABLE_ESTADO' : 'Estado',
                    'TABLE_ESTADO_BANCO' : 'Estado Banco',
                    'TABLE_OBS_BANCO' : 'Observación Banco',
                    'TABLE_DATE' : 'Fecha de la transacción',
                    'TABLE_USERNAME' : 'Nombre de Usuario',
                    'TABLE_ACCOUNT_NUMBER' : 'Identificador del Usuario',
                    'TABLE_REFERENCE' : 'Referencia',
                    'TABLE_KYCYN' : 'KyCyn',
                    'TABLE_CUSTOMER_CELLPHONE' : 'Celular',
                    'TABLE_MERCHANT' : 'Comercio',
                    'TABLE_TIPO_OPERACION':'Tipo de Operación',
                    'TABLE_CODOPERBANCO' : 'Operación',
                    'TABLE_CUENTA' : 'Cuenta',
                    'TABLE_TRANS_OC' : 'Transacción OC',
                    'TABLE_TRANS_ID' : 'TransID',
                    
                },
                'NOMINA_VALIDADO' : {
                    'TITULO': 'Detalles de Nómina',
                    'SUBTITULO': 'Listado de Transacciones',
                    'TABLE_TRANS_NRO': 'Nro Transaccion',
                    'TABLE_ORDEN_COMPRA' : 'Orden de Compra',
                    'TABLE_NOTA_CREDITO' : 'Nota de Credito',
                    'TABLE_CUENTA_DEST' : 'Cuenta de Destino',
                    'TABLE_TIPO_CUENTA' : 'Tipo de Cuenta',
                    'TABLE_BANCO' : 'Banco',
                    'TABLE_SHOPPER_ID' : 'ID Shopper',
                    'TABLE_ID_CLIENTE' : 'ID Cliente',
                    'TABLE_NOMBRE' : 'Nombre Cliente',
                    'TABLE_DEPARTAMENTO' : 'Departmento',
                    'TABLE_MONEDA' : 'Moneda',
                    'TABLE_MONTO' : 'Monto',
                    'TABLE_CANAL' : 'Canal',
                    'TABLE_CORREO' : 'Correo',
                    'TABLE_ESTADO' : 'Estado',
                    'TABLE_ESTADO_BANCO' : 'Estado Banco',
                    'TABLE_OBS_BANCO' : 'Observación Banco',
                    'TABLE_TIPO_OPERACION':'Tipo de Operación'
                },
                'NOMINA_ERROR' : {
                    'TITULO': 'Detalle Nómina Error',
                    'SUBTITULO': 'Lista de Transacciones con Error',
                    'TABLE_TRANS_NRO': 'Nro Transaccion',
                    'TABLE_ORDEN_COMPRA' : 'Orden de Compra',
                    'TABLE_NOTA_CREDITO' : 'Nota de Credito',
                    'TABLE_CUENTA_DEST' : 'Cuenta de Destino',
                    'TABLE_TIPO_CUENTA' : 'Tipo de Cuenta',
                    'TABLE_BANCO' : 'Banco',
                    'TABLE_SHOPPER_ID' : 'ID Shopper',
                    'TABLE_ID_CLIENTE' : 'ID Cliente',
                    'TABLE_NOMBRE' : 'Cliente',
                    'TABLE_DEPARTAMENTO' : 'Departmento',
                    'TABLE_CCI' : 'Cuenta CCI ',
                    'TABLE_MONEDA' : 'Moneda',
                    'TABLE_MONTO_USD' : 'Monto en Dólares',
                    'TABLE_MONTO' : 'Monto',
                    'TABLE_CANAL' : 'Canal',
                    'TABLE_CORREO' : 'Correo',
                    'TABLE_DETALLE_ERROR' : 'Detalle Error',
                    'TABLE_TIPO_OPERACION':'Tipo de Operación',
                    'TABLE_CUENTA':'Cuenta',
                    'TABLE_MOTIVO_RECHAZO':'Motivo de Rechazo',
                    'TABLE_OBSERVACION':'Observación'
                }											
            },
            'APROBAR': {
                'TITULO': 'Aprobar Nóminas',
                'SUBTITULO': 'Aprobar Nóminas',
                'TABLE_ID': 'ID',
                'TABLE_ARCHIVO' : 'Archivo',
                'TABLE_SUBIDO_POR' : 'Subido Por',
                'TABLE_ESTADO' : 'Estado',
                'TABLE_MONEDA' : 'Moneda',
                'TABLE_TOTAL' : 'Total Monto',
                'TABLE_TOTAL_#' : '# Total',
                'TABLE_FECHA_CARGA' : 'Fecha Carga',
                'TABLE_ULT_ACTUALIZACION' : 'Ultima Actualización',
                'TABLE_GRUPO' : 'Grupo',
                'TABLE_PAIS' : 'País',
                'TABLE_ACCIONES' : 'Acciones'
            },
            'BASE_EXPORT_FILE':{
                'FILE_NAME': 'Reporte',
                'COLUMN_TRANS_ID': 'TransID',
                'COLUMN_TRANS_OC': 'Transacción OC',
                'COLUMN_FECHA_TRANSACCION' : 'Fecha de la Transacción',
                'COLUMN_NOMBRE_USUARIO' : 'Nombre de Usuario',
                'COLUMN_ID_USUARIO' : 'Identificador del Usuario',
                'COLUMN_MONTO' : 'Monto',
                'COLUMN_MONEDA' : 'Moneda',
                'COLUMN_MONTO_DOLAR' : 'Monto en Dólares',
                'COLUMN_COMERCIO' : 'Comercio',
                'COLUMN_BANCO' : 'Banco',
                'COLUMN_NOMBRE_CLIENTE' : 'Cliente',
                'COLUMN_TIPO_CUENTA' : 'Tipo de Cuenta',
                'COLUMN_CUENTA_DESTINO' : 'Cuenta de Destino',
                'COLUMN_TIPO_ID_CLIENTE' : 'Tipo Documento',
                'COLUMN_ID_CLIENTE' : 'Documento',
                'COLUMN_DEPARTAMENTO' : 'Departamento',
                'COLUMN_NRO_CCI' : 'Cuenta CCI',
                'COLUMN_REFERENCIA' : 'Referencia',
                'COLUMN_KYCYN' : 'KyCyn',
                'COLUMN_CORREO' : 'Correo',
                'COLUMN_CELULAR' : 'Celular',
                'COLUMN_NOTA_CREDITO' : 'Nota de Credito',
                'COLUMN_ESTADO' : 'Estado',
                'COLUMN_ESTADO_BANCO' : 'Estado Banco',
                'COLUMN_OBSERVACIONES_BANCO' : 'Observación Banco',
                'COLUMN_CANAL' : 'Canal',
                'COLUMN_ARCHIVO' : 'Archivo',
                'COLUMN_PROCESO' : 'Proceso',
                'COLUMN_SHOPPER_ID' : 'ID Cliente',
                'COLUMN_DETALLE_ERROR' : 'Detalle Error',
                'COLUMN_TIPO_OPERACION':'Tipo de Operación',
                'COLUMN_CODOPERBANCO' : 'Operación',
                'COLUMN_CUENTA' : 'Cuenta',
                'COLUMN_MOTIVO_RECHAZO':'Motivo de Rechazo',
                'COLUMN_OBSERVACION':'Observación'
            }
        },
        'REPORTE': {
            'OPERATIVO': {
                'TITULO': 'Reporte Operativo',
                'TABLE_COMMERCE':'Comercio',
                'TABLE_AGRUPACION_ID':'ID Agrupación',
                'TABLE_MONTO_VALIDADO':'Monto Validado',
                'TABLE_MERCHANT': 'Merchant',
                'TABLE_NRO_OPERACION':'Nro. Operación'
            },
            'DESCARGABLE': {
                'TITULO': 'Reporte Descargable',
                'TABLE_ARCHIVO_NOMBRE': 'Nombre reporte',
                'TABLE_QUANTITY': 'Cantidad',
                'TABLE_REPORT_TYPE': 'Tipo reporte',
                'TABLE_FROM_DATE': 'Desde',
                'TABLE_TO_DATE': 'Hasta',
                'TABLE_CREATION_DATE': 'Creación'
            },
            'BASE': {						
                'TITULO': 'Reporte de Transacciones',
                'TABLE_NOMINA_NOMBRE': 'Nómina Nombre',
                'TABLE_NOMINA_ID' : 'ID Nómina',
                'TABLE_GRUPO' : 'Grupo',
                'TABLE_PAIS' : 'País',
                'TABLE_TRANS_OC' : 'Transaccion OC',
                'TABLE_TRANS_NC' : 'Transaccion NC',
                'TABLE_NRO_CUENTA' : 'Nro de Cuenta',
                'TABLE_BANCO' : 'Banco',
                'TABLE_TIPO_DOCUMENTO' : 'Tipo Documento Shopper',
                'TABLE_NRO_DOCUMENTO' : 'Nro Documento',
                'TABLE_NOMBRE' : 'Nombre',
                'TABLE_MONEDA' : 'Moneda',
                'TABLE_MONTO' : 'Monto',
                'TABLE_FECHA_MODIF' : 'Fecha Modificacion',
                'TABLE_ESTADO' : 'Estado',
                'TABLE_ESTADO_BANCO' : 'Estado Banco',
                'TABLE_OBS_BANCO' : 'Observación Banco',
                'TABLE_DEPARTAMENTO' : 'Departamento',
                'TABLE_FECHA_CARGA' : 'Fecha de Carga',
                'TABLE_FECHA_APROV' : 'Fecha de Aprobación',
                'TABLE_FECHA_ATENCION' : 'Fecha de Atención',
                'TABLE_FECHA_CIERRE' : 'Fecha de Banco Rpta',
                'TABLE_INTERPLAZA' : 'Comis. Interplaza',
                'TABLE_TARIFA' : 'Fee',
                'TABLE_IMPUESTO' : 'Tax',
                'TABLE_CODOPERBANCO' : 'Cod. Op. Banco',
                'TABLE_CCINUMBER' : 'Número CCI',
                'TABLE_REFERENCE' : 'Referencia',
                'TABLE_MERCHANT' : 'Comercio',
		        'TABLE_SHOPPER_ID' : 'ID Shopper',	
                'TABLE_MENSAJE_ERROR' : 'Observación Rechazo',
                'TABLE_MOTIVO_RECHAZO' : 'Motivo Rechazo',
                'TABLE_FEE_TAX' : 'Total Fee + Tax',
                'TABLE_NET_MERCHANT' : 'Net-To-Merchant'

                											
            },
            'BASE_EXPORT_FILE':{
                'FILE_NAME': 'Reporte',
                'COLUMN_GRUPO': 'Grupo',
                'COLUMN_PAIS' : 'País',
                'COLUMN_NOMINA_NOMBRE' : 'Nomina Nombre',
                'COLUMN_ID_NOMINA' : 'ID Nomina',
                'COLUMN_TRANSACCION_OC' : 'Transaccion OC',
                'COLUMN_TRANSACCION_NC' : 'Transaccion NC',
                'COLUMN_NRO_CUENTA' : 'Número de Cuenta',
                'COLUMN_TIPO_CUENTA' : 'Tipo de Cuenta',
                'COLUMN_BANCO' : 'Banco',
                'COLUMN_NRO_CCI' : 'Número CCI',
                'COLUMN_TIPO_DOCUMENTO' : 'Tipo de Documento',
                'COLUMN_NRO_DOCUMENTO' : 'Documento',
                'COLUMN_NOMBRE' : 'Nombre',
                'COLUMN_CORREO' : 'Correo',
                'COLUMN_CELULAR' : 'Celular',
                'COLUMN_MONTO' : 'Monto',
                'COLUMN_MONEDA' : 'Moneda',
                'COLUMN_USUARIO_CARGA' : 'Usuario Carga',
                'COLUMN_FECHA_MODIF' : 'Fecha Modificación',
                'COLUMN_ESTADO_BANCO' : 'Estado Banco',
                'COLUMN_ESTADO' : 'Estado',
                'COLUMN_OBSERVACION_BANCO' : 'Observacion Banco',
                'COLUMN_TARIFA' : 'Fee',
                'COLUMN_IMPUESTO' : 'Tax',
                'COLUMN_COD_OP_BANCO' : 'Cod. Op. Banco',
                'COLUMN_DEPARTAMENTO' : 'Departamento',
                'COLUMN_COMISION_INTERPLAZA' : 'Comisión Interplaza',
                'COLUMN_FECHA_CARGA' : 'Fecha de Carga',
                'COLUMN_FECHA_APROV' : 'Fecha de Aprobación',
                'COLUMN_FECHA_ATENCION' : 'Fecha de Atención',
                'COLUMN_FECHA_CIERRE' : 'Fecha de Banco Rpta',
                'COLUMN_REFERENCE' : 'Referencia',
                'COLUMN_MERCHANT' : 'Comercio',
		        'COLUMN_SHOPPER_ID' : 'ID Cliente',
                'COLUMN_MENSAJE_ERROR' : 'Observación Rechazo',
                'COLUMN_MOTIVO_RECHAZO' : 'Motivo Rechazo',
                'COLUMN_FEE_TAX' : 'Total Fee + Tax',
                'COLUMN_NET_MERCHANT' : 'Net-To-Merchant',
		'COLUMN_OPERATIVO_MERCHANT': 'Merchant',
                'COLUMN_COMMERCE' : 'Comercio',
                'COLUMN_ID_AGRUPACION' : 'ID Agrupación',
                'COLUMN_MONTO_VALIDADO' : 'Monto Validado',
                'COLUMN_OPERATION_NUMBER': 'Numero operacion'
                
            }
        },
        'BANCO': {
            'ENVIO': {						
                'TITULO': 'Envio al Banco',
                'SUBTITULO': 'Lista de nóminas por enviar',
                'TABLE_ID': 'ID',
                'TABLE_GRUPO' : 'Grupo',
                'TABLE_PAIS' : 'País',
                'TABLE_BANCO' : 'Banco',
                'TABLE_MONEDA' : 'Moneda',
                'TABLE_TIPO_TRANSF' : 'Tipo Transferencia',
                'TABLE_TOTAL' : 'Total',
                'TABLE_TOTAL_#' : '# Total',
                'TABLE_FECHA_CREACION' : 'Fecha Creación',
                'TABLE_FECHA_ACTUALIZACION' : 'Fecha Actualización',
                'TABLE_ACCIONES' : 'Acciones',
                'TABLE_H2H' : 'Transferencia al Host'
                											
            },
            'RESP_BANCO': {						
                'TITULO': 'Respuesta del Banco',
                'SUBTITULO': 'Lista de respuestas',
                'TABLE_ID': 'ID',
                'TABLE_GRUPO' : 'Grupo',
                'TABLE_PAIS' : 'País',
                'TABLE_ESTADO' : 'Estado',
                'TABLE_BANCO' : 'Banco',
                'TABLE_MONEDA' : 'Moneda',
                'TABLE_TOTAL' : 'Total',
                'TABLE_TOTAL_#' : '# Total',
                'TABLE_FECHA_CARGA' : 'Fecha Carga',
                'TABLE_SUBIR_ARCHIVO' : 'Subir Archivo',
                'TABLE_ACCIONES' : 'Acciones',
                'TABLE_ACCIONES_2' : 'Acciones'
                											
            },
            'RESP_MANUAL_BANCO': {						
                'TITULO': 'Respuesta Manual del Banco',
                'SUBTITULO': 'Lista de nóminas por responder',
                'TABLE_ID': 'ID',
                'TABLE_GRUPO' : 'Grupo',
                'TABLE_PAIS' : 'País',
                'TABLE_ESTADO' : 'Estado',
                'TABLE_BANCO' : 'Banco',
                'TABLE_MONEDA' : 'Moneda',
                'TABLE_MONTO' : 'Monto',
                'TABLE_TOTAL_#' : '# Total',
                'TABLE_FECHA_CREACION' : 'Fecha creación',
                'TABLE_FECHA_MODIF' : 'Fecha modificación',
                'TABLE_ACCIONES' : 'Acciones'								
            },
            'CONSTANCIA': {						
                'TITULO': 'Constancias',
                'SUBTITULO': 'Lista de constancias',
                'TABLE_ID': 'Identificador',
                'TABLE_ID_NOMINA': 'Nomina Id',
                'TABLE_GRUPO_NOMINA': 'Nomina grupo Id',
                'TABLE_GRUPO' : 'Grupo',
                'TABLE_PAIS' : 'Pais',
                'TABLE_ESTADO' : 'Estado',
                'TABLE_FECHA_CREACION' : 'Fecha de creación',
                'TABLE_NOMBRE_ARCHIVO' : 'Nombre de archivo',
                'TABLE_ACCIONES' : 'Acciones'								
            },
            'RESP_BANCO_AGRUPACION': {						
                'TITULO': 'Respuesta del Banco - Agrupaciones',
                'SUBTITULO': 'Lista de agrupaciones',
                'TABLE_IDENTIFIER':'ID de agrupación',
                'TABLE_ID': 'ID',
                'TABLE_IDS': 'ID de Nominas',
                'TABLE_GRUPO' : 'Grupo',
                'TABLE_PAIS' : 'País',
                'TABLE_ESTADO' : 'Estado',
                'TABLE_BANCO' : 'Banco',
                'TABLE_MONEDA' : 'Moneda',
                'TABLE_TOTAL' : 'Total',
                'TABLE_TOTAL_#' : '# Total',
                'TABLE_FECHA_CARGA' : 'Fecha Carga',
                'TABLE_SUBIR_ARCHIVO' : 'Subir Archivo',
                'TABLE_ARCHIVO_BANCO' : 'Archivo bancario',
                'TABLE_ARCHIVO_H2H' : 'Nombre archivo H2H',
                'TABLE_TIPO' : 'Tipo de proceso',
                'TABLE_DELIVERY' : 'Delivery',
                'TABLE_ACCIONES' : 'Acciones',
                'TABLE_ACCIONES_2' : 'Acciones'
                											
            },
            'BASE_EXPORT_FILE':{
                'FILE_NAME': 'Reporte',
                'COLUMN_NOMBRE': 'nombre',
                'COLUMN_APELLIDO' : 'apellido',
                'COLUMN_CUIL' : 'cuit',
                'COLUMN_MAIL' : 'email',
                'COLUMN_CBU' : 'CBU',
                'COLUMN_CVU' : 'CVU',
                'COLUMN_ALIAS' : 'ALIAS',
                'COLUMN_FECHA' : 'fecha',
                'COLUMN_CONCEPTO' : 'concepto',
                'COLUMN_TIPO_CUENTA' : 'Tipo de Cuenta',
                'COLUMN_IMPORTE' : 'importe'
            },
	     'RESP_ACTUALIZAR_ESTADO': {						
                'TITULO': 'Actualizar estado',
                'TABLE_NOMINA_ID' : 'ID Nómina',
                'TABLE_GRUPO' : 'Grupo',
                'TABLE_PAIS' : 'País',
                'TABLE_TRANS_OC' : 'Transaccion OC',
                'TABLE_BANCO' : 'Banco',
                'TABLE_NRO_DOCUMENTO' : 'Nro Documento',
                'TABLE_NOMBRE' : 'Nombre',
                'TABLE_MONEDA' : 'Moneda',
                'TABLE_MONTO' : 'Monto',
                'TABLE_ESTADO' : 'Estado',
                'TABLE_OBS_BANCO' : 'Observación Banco',
                'TABLE_ACCIONES' : {
                    'ACTUALIZAR':'Actualizar',
                    'NOTIFICAR':'Notificar'
                },
                'MODAL' : {
                    'TITULO':'Cambiar estado',
                    'GRUPO' : 'Grupo',
                    'TRANS_OC' : 'Transaccion OC',
                    'ESTADO' : 'Estado',
                    'MONTO' : 'Monto',
                    'NOMINA_ID' : 'ID Nómina',
                    'FECHA' : 'Fecha Hora Autorización',
                    'GUARDAR':'Guardar',
                    'DESCRIPCION':'Motivo/Detalle',
                }
                											
            }
        },
        'BALANCE': {
            'REGISTRO': {
                'TITULO': 'Registro de Movimientos',
                'TABLE_GRUPO' : 'Grupo',
                'TABLE_PAIS' : 'País',
                'TABLE_FECHA_DEPOSITO' : 'Fecha de movimiento',
                'TABLE_BANCO' : 'Banco',
                'TABLE_DESCRIPCION' : 'Descripción',
                'TABLE_NRO_DOCUMENTO' : 'Nro Documento',
                'TABLE_NRO_CUENTA' : 'Nro de Cuenta',
                'TABLE_TIPO' : 'Movimiento',
                'TABLE_TIPO_REGISTRO' : 'Tipo Registro',
                'TABLE_MONEDA' : 'Moneda',
                'TABLE_MONTO' : 'Monto',
                'TABLE_ACCIONES' : 'Acciones'											
            },
            'CONSULTA': {
                'TITULO': 'Consulta de Balances',
                'TABLE_GRUPO' : 'Grupo',
                'TABLE_PAIS' : 'País',
                'TABLE_BANCO' : 'Banco',
                'TABLE_MONEDA' : 'Moneda',
                'TABLE_TOTAL_ING' : 'Total Ingreso',
                'TABLE_TOTAL_EGR' : 'Total Egreso',
                'TABLE_SALDO_TOTAL' : 'Saldo del Periodo',
                'TABLE_SALDO_ANTERIOR' : 'Saldo Anterior',
                'TABLE_SALDO_ACUMULADO' : 'Saldo Acumulado'											
            },
            'DETAIL': {
                'TITULO': 'Consulta de Movimientos',
                'TABLE_FECHA' : 'Fecha',
                'TABLE_DESCRIPCION' : 'Descripción',
                'TABLE_TIPO' : 'Movimiento',
                'TABLE_ID_NOMINA' : 'ID Nómina',
                'TABLE_NOMBRE_NOMINA' : 'Nombre de Nómina',
                'TABLE_MONEDA' : 'Moneda',
                'TABLE_IMPORTE' : 'Importe',
                'TABLE_SALDO' : 'Saldo',
                'MENSAJE':{
                    'MSG_INGRESO' : 'Transferencia de fondos Payouts',
                    'MSG_EGRESO' : 'Payouts exitosos',
                    'MSG_SALDO' : 'Saldo anterior',
                }											
            },
            'BASE_EXPORT_FILE':{
                'FILE_NAME': 'Reporte',
                'COLUMN_COMERCIO': 'Comercio',
                'COLUMN_FECHA_DEPOSITO' : 'Fecha de deposito',
                'COLUMN_BANCO' : 'Banco',
                'COLUMN_MONEDA' : 'Moneda',
                'COLUMN_NRO_DOCUMENTO' : 'Nro Documento',
                'COLUMN_NRO_CUENTA' : 'Número de Cuenta',
                'COLUMN_MONTO' : 'Monto',
                'COLUMN_TIPO' : 'Movimiento',
                'COLUMN_TIPO_REGISTRO' : 'Tipo Registro',
                'COLUMN_TOTAL_INGRESO' : 'Total Ingreso',
                'COLUMN_TOTAL_EGRESO' : 'Total Egreso',
                'COLUMN_SALDO_TOTAL' : 'Saldo del Periodo',
                'COLUMN_SALDO_ANTERIOR' : 'Saldo Anterior',
                'COLUMN_SALDO_ACUMULADO' : 'Saldo Acumulado',
                'COLUMN_DESCRIPTION':"Descripción",
                'COLUMN_PAIS':"País"
            }
        },
        'ADMIN': {
            'LISTA_USUARIOS': {
                'TITULO': 'Usuarios',
                'SUBTITULO': 'Lista de Usuarios',
                'TABLE_NOM_USUARIO' : 'Nombre de Usuario',
                'TABLE_NOMBRE' : 'Nombre',
                'TABLE_APELLIDO' : 'Apellido',
                'TABLE_GRUPO' : 'Grupo',
                'TABLE_CORREO' : 'Correo Electronico',
                'TABLE_ROL' : 'Rol',
                'TABLE_ESTADO' : 'Estado',
                'TABLE_ACCIONES' : 'Acciones',
                'MODAL': {
                    'TITULO_NUEVO_USU' : 'Nuevo Usuario',
                    'TITULO_MODIF_USU' : 'Editar Usuario',
                    'NOMBRE_DE_USU' : 'Nombre de Usuario *',
                    'CORREO_USU' : 'Email *',
                    'NOMBRES_USU' : 'Nombres',
                    'APELLIDOS_USU' : 'Apellidos',
                    'DOCUMENTO_USU' : 'Documento',
                    'NRO_DOCUMENTO_USU' : 'Nro Documento',
                    'GRUPO_USU' : 'Grupo',
                    'ELEGIR_COMERCIO_USU' : 'Elegir Comercios',
                    'ROL_USU' : 'Rol',
                    'HABILITAR_NOTIFICACION' : 'Habilitar Notificación',
                    'GUARDAR_USU' : 'Guardar',
                    'MODIFICAR_USU' : 'Modificar'
                }											
            },
            'LISTA_COMERCIOS': {		
                'TITULO': 'Comercios',
                'SUBTITULO': 'Lista de Comercios',
                'TABLE_COMERCIO' : 'Comercio',
                'TABLE_NOM_CONTACTO' : 'Nombre Contacto',
                'TABLE_GRUPO' : 'Grupo',
                'TABLE_RUC' : 'RUC',
                'TABLE_CORREO' : 'Correo',
                'TABLE_ESTADO' : 'Estado',
                'TABLE_SUBIR_IMG' : 'Subir Imagen',
                'TABLE_ACCIONES' : 'Acciones',
                'MODAL': {
                    'TITULO_NUEVO_COM' : 'Nuevo Comercio',
                    'TITULO_MODIF_COM' : 'Editar Comercio',
                    'TITULO_SUBIR_IMG_COM' : 'Subir logo',
                    'NOMBRE_COM' : 'Nombre *',
                    'NOMBRE_CONTACTO_COM' : 'Nombre Contacto',
                    'DIRECCION_COM' : 'Dirección',
                    'NRO_RUC_COM' : 'Nro RUC',
                    'TELEFONO_COM' : 'Telefono',
                    'CORREO_COM' : 'Correo',
                    'GRUPO_COM' : 'Grupo',
                    'PAIS_COM' : 'País',
                    'SELECCION_MONEDA_COM' : 'Seleccione monedas',
                    'GUARDAR_COM' : 'Guardar',
                    'MODIFICAR_COM' : 'Modificar'
                }											
            },
            'LISTA_ROLES': {
                'TITULO': 'Roles',
                'SUBTITULO': 'Lista de Roles',
                'TABLE_NOMBRE' : 'Nombre',
                'TABLE_NOM_CONTACTO' : 'Nombre Contacto',
                'TABLE_FECHA_CREACION' : 'Fecha de creación',
                'TABLE_SUPER_ADMIN' : 'Super Admin',
                'TABLE_ESTADO' : 'Estado',
                'TABLE_ACCIONES' : 'Acciones',
                'MODAL': {
                    'TITULO_NUEVO_ROL' : 'Nuevo Rol',
                    'TITULO_MODIF_ROL' : 'Editar Rol',
                    'TITULO_PERMISOS_ROL' : 'Permisos',
                    'NOMBRE_ROL' : 'Nombre *',
                    'NOMBRE_VISTA_ROL' : 'Nombre Vista',
                    'ADMINISTRADOR' : 'Administrador',
                    'ESTADO' : 'Estado',
                    'GUARDAR_ROL' : 'Guardar',
                    'MODIFICAR_ROL' : 'Modificar'
                }										
            },
            'LISTA_GRUPOS_COMERCIO': {
                'TITULO': 'Grupos',
                'SUBTITULO': 'Lista de Grupos',
                'TABLE_NOMBRE' : 'Nombre',
                'TABLE_NOM_CONTACTO' : 'Nombre Contacto',
                'TABLE_CORREO' : 'Correo',
                'TABLE_TELEFONO' : 'Telefono',
                'TABLE_ACCIONES' : 'Acciones',
                'MODAL': {
                    'TITULO_NUEVO_GRUPO' : 'Nuevo Grupo',
                    'TITULO_MODIF_GRUPO' : 'Editar Grupo',
                    'NOMBRE_GRUPO' : 'Nombre *',
                    'NOMBRE_CONTACTO_GRUPO' : 'Nombre Contacto',
                    'TELEFONO_GRUPO' : 'Telefono',
                    'CORREO_GRUPO' : 'Correo',
                    'GUARDAR_GRUPO' : 'Guardar',
                    'MODIFICAR_GRUPO' : 'Modificar'
                }											
            }, 
            'COMERCIO': {
                'TITULO': 'Comercio',
                'SUBTITULO': 'Datos del Comercio',
                'LLAVE_SHA' : 'LLAVE',
                'LISTA':{
                    'TABLA_ID': 'ID',
                    'TABLA_TIPO' : 'Tipo',
                    'TABLA_KEY' : 'Llave',
                    'TABLA_ESTADO' : 'Estado',
                    'TABLA_FECHA' : 'Fecha de Registro',

                }										
            },
            'TARIFAS': {
                'TITULO': 'Tarifas',
                'SUBTITULO': 'Lista de Tarifas',
                'MERCHANT': 'Comercio',
                'RATE_TYPE' : 'Tipo Comisión',
                'CURRENCY' : 'Moneda',
                'MAX_RATE':'Comisión Máxima',
                'MIN_RATE':'Comisión Mínima',
                'PERCENTAGE_RATE':'Comisión Porcentaje (%)',
                'FIXED':'Comisión Fija',
                'TAX':'Impuesto (%)',
                'STATUS':'Estado',
                'SAVE' : 'Guardar',
                'CANCEL' : 'Cancelar',
                'NEW':'Nueva',
                'UPDATE' : 'Modificar'
            }
        }
    }
};
