import { ComercioEditModel } from './../../models/comercio-edit-model.model';
import { Injectable, Pipe, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfiguracionUrl } from '../../../configuracionUrl';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UsuarioListarModel } from '../../models/usuarioListarModel.model';
import { ComercioGrupoModel } from 'app/main/models/comercioGrupoModel.model';
import { Rol } from '../../models/rol.model';
import { UserLoginService } from '../../pages/services/login-user.services';
import { CmbGruposModel } from 'app/main/models/cmbGruposModel.model';
import { UsuarioCrearModel } from 'app/main/models/usuarioCrearModel.model';
import { DepartamentoModel } from 'app/main/models/departamentoModel.model';
import { ProvinciaModel } from 'app/main/models/provinciaModel.model';
import { DistritoModel } from 'app/main/models/distritoModel.model';
import { ComercioToolBarModel } from 'app/main/models/comercioToolBarModel.model';
import { ComercioListarModel } from 'app/main/models/comercioListarModel.model';
import { ComercioMovimientosModel } from 'app/main/models/comercioMovimientosModel.model';
import { ComisionModel } from 'app/main/models/comisionModel.model';
import { CognitoService } from 'app/cognito.service';
import * as  AmazonCognitoIdentity from "amazon-cognito-identity-js";
import Swal from 'sweetalert2';
//import { map } from 'rxjs/operators';
//import { HttpClient, RequestOptions } from '@angular/common/http';

export interface CognitoCallback {
  cognitoCallback(message: string, result: any): void;
  handleMFAStep?(challengeName: string, challengeParameters: ChallengeParameters, callback: (confirmationCode: string) => any): void;
}

export interface ChallengeParameters {
  CODE_DELIVERY_DELIVERY_MEDIUM: string;
  CODE_DELIVERY_DESTINATION: string;
}

@Injectable({
  providedIn: 'root'
})

export class AdminService {
  baseUrl: ConfiguracionUrl;
  urlApi: string;
  tipoUsuario: string;
  headers: any;
  option: any;
  perfil: any;
  datagen: any;
  idtoken: string;
  idDevice: string;
  tokenAcess: string;

  constructor(private httpClient: HttpClient,
    getParametroCognito: UserLoginService,
    @Inject(CognitoService) public cognitoUtil: CognitoService) {
    this.baseUrl = new ConfiguracionUrl();
    this.idtoken = getParametroCognito.renovarToken();
    this.headers = new HttpHeaders({
      'Content-type': 'application/json;charset=utf8',
      'Authorization': this.idtoken
      // 'DeviceKey' : this.idDevice,
      // 'AccessToken' : this.tokenAcess
    });
    //   this.option = new RequestOptions({headers:this.headers});
  }



  //::::::::::::::::::::::::::::::: USUARIOS ::::::::::::::::::::::::::::::://
  usuarios_listar(jsonsend): Observable<UsuarioListarModel[]> {
    return this.httpClient.get<UsuarioListarModel[]>(this.baseUrl.getUrlApiUsuarios() + 'usuarios?username=' + jsonsend['username'], { headers: this.headers });
  }

  usuarios_agregar(cabecera: UsuarioCrearModel): Observable<UsuarioCrearModel> {
    return this.httpClient.post<UsuarioCrearModel>(this.baseUrl.getUrlApiUsuarios() + 'usuarios', cabecera, { headers: this.headers });
  }

  usuario_editar(editarUsuario: UsuarioCrearModel) {
    return this.httpClient.get<UsuarioCrearModel>(this.baseUrl.getUrlApiUsuarios() + 'usuariosbyId/' + editarUsuario.userId, { headers: this.headers });
  }

  usuario_actualizar(actualizarUsuario: UsuarioCrearModel) {
    return this.httpClient.put<UsuarioCrearModel>(this.baseUrl.getUrlApiUsuarios() + 'usuarios/' + actualizarUsuario.username, actualizarUsuario, { headers: this.headers });
  }

  estado_activo_Usuario(id:any){
    const body = {}
    return this.httpClient.put<UsuarioListarModel>(this.baseUrl.getUrlApiUsuarios() + 'usuarios_activar/' + id, body, { headers: this.headers });
  }

  estado_inactivo_Uusario(id:any){
    const body = {}
    return this.httpClient.put<UsuarioListarModel>(this.baseUrl.getUrlApiUsuarios() + 'usuarios_inactivar/' + id, body, { headers: this.headers });
  }


  //::::::::::::::::::::::::::::::: COMISIONES ::::::::::::::::::::::::::::::://

  comision_agregar(nuevaComision: any): Observable<ComisionModel> {
    return this.httpClient.post<ComisionModel>(this.baseUrl.getUrlApiComisiones() + 'commission', nuevaComision, { headers: this.headers });
  }

  comision_listar(): Observable<ComisionModel[]> {
    return this.httpClient.get<ComisionModel[]>(this.baseUrl.getUrlApiComisiones() + 'commission/', { headers: this.headers });
  }

  comision_listar_comercio(idComercio: number): Observable<ComisionModel[]> {
    return this.httpClient.get<ComisionModel[]>(this.baseUrl.getUrlApiComisiones() + 'commission/merchant/' + idComercio, { headers: this.headers });
  }

  comision_actualizar(comision: ComisionModel) {
    return this.httpClient.put<ComercioEditModel>(this.baseUrl.getUrlApiComisiones() + 'commission', comision, { headers: this.headers });
  }

  //::::::::::::::::::::::::::::::: COMERCIOS ::::::::::::::::::::::::::::::://
  comercios_listar(): Observable<ComercioListarModel[]> {
    return this.httpClient.get<ComercioListarModel[]>(this.baseUrl.getUrlApi() + 'comercios', { headers: this.headers });
  }

  comercios_agregar(nuevoComercio: any): Observable<ComercioMovimientosModel> {
    return this.httpClient.post<ComercioMovimientosModel>(this.baseUrl.getUrlApi() + 'comercios', nuevoComercio, { headers: this.headers });
  }

  comercio_editar(editarUsuario: ComercioEditModel) {
    return this.httpClient.get<ComercioEditModel>(this.baseUrl.getUrlApi() + 'comercios/' + editarUsuario.comercioid, { headers: this.headers });
  }

  comercio_actualizar(actualizarComercio: ComercioEditModel) {
    return this.httpClient.put<ComercioEditModel>(this.baseUrl.getUrlApi() + 'comercios/' + actualizarComercio.comercioid, actualizarComercio, { headers: this.headers });
  }

  comercio_buscar(idComercio: number): Observable<ComercioListarModel> {
    return this.httpClient.get<ComercioListarModel>(this.baseUrl.getUrlApi() + 'comercios/' + idComercio, { headers: this.headers });
  }

  obtener_grupos(): Observable<ComercioGrupoModel[]> {
    return this.httpClient.get<ComercioGrupoModel[]>(this.baseUrl.getUrlApi() + 'comerciosgrupo', { headers: this.headers });
  }

  obtener_dpto(): Observable<DepartamentoModel[]> {
    return this.httpClient.get<DepartamentoModel[]>(this.baseUrl.getUrlApi() + 'ubigeo/departamento', { headers: this.headers });
  }

  cmb_provincia_listar(idDept: any): Observable<ProvinciaModel[]> {
    return this.httpClient.get<ProvinciaModel[]>(this.baseUrl.getUrlApi() + 'ubigeo/provincia/' + idDept, { headers: this.headers });
  }

  cmb_distrito_listar(idDept: any, idProv: any): Observable<DistritoModel[]> {
    return this.httpClient.get<DistritoModel[]>(this.baseUrl.getUrlApi() + 'ubigeo/distrito/' + idDept + '/' + idProv, { headers: this.headers });
  }

  comercio_imagen_subir(imagen: any): Observable<any[]> {
    return this.httpClient.post<any[]>(this.baseUrl.getUrlApi() + 'comercios_uploadlogo', imagen, { headers: this.headers });
  }

  estado_activo(id: any) {
    const body = {}
    return this.httpClient.put<ComercioMovimientosModel>(this.baseUrl.getUrlApi() + 'comercios_activar/' + id, body, { headers: this.headers });
  }

  estado_inactivo(id: any) {
    const body = {}
    return this.httpClient.put<ComercioMovimientosModel>(this.baseUrl.getUrlApi() + 'comercios_inactivar/' + id, body, { headers: this.headers });
  }

  comercio_eliminar(comercioId : any){
    return this.httpClient.delete<ComercioMovimientosModel>(this.baseUrl.getUrlApi() + 'comercios/' + comercioId, { headers: this.headers });
  }

  obtener_paises(): Observable<any[]>{
    return this.httpClient.get<any[]>(this.baseUrl.getUrlApi() + 'pais_activos', { headers: this.headers });
  }

  cmb_pais_moneda_listar(paisId):Observable<any[]>{
    return this.httpClient.get<any[]>(this.baseUrl.getUrlApi() + 'pais_moneda/'+paisId, { headers: this.headers });
  }


  //::::::::::::::::::::::::::::::: ROLES ::::::::::::::::::::::::::::::://
  roles_listar(): Observable<Rol[]> {
    return this.httpClient.get<Rol[]>(this.baseUrl.getUrlApi() + 'roles', { headers: this.headers });
  }

  roles_agregar(nuevoRol: any): Observable<Rol> {
    return this.httpClient.post<Rol>(this.baseUrl.getUrlApi() + 'roles', nuevoRol, { headers: this.headers });
  }

  roles_editar(editarRol: Rol) {
    return this.httpClient.get<Rol>(this.baseUrl.getUrlApi() + 'roles/' + editarRol.id, { headers: this.headers });
  }

  roles_actualizar(actualizarRol: Rol) {
    return this.httpClient.put<Rol>(this.baseUrl.getUrlApi() + 'roles/' + actualizarRol.id, actualizarRol, { headers: this.headers });
  }


  //::::::::::::::::::::::::::::::: COMERCIO GRUPOS ::::::::::::::::::::::::::::::://
  obtenerComerciosGrupos(): Observable<ComercioGrupoModel[]> {
    return this.httpClient.get<ComercioGrupoModel[]>(this.baseUrl.getUrlApi() + 'comerciosgrupo', { headers: this.headers });
  }

  agregar_grupos(nuevoGrupo: any): Observable<ComercioGrupoModel> {
    return this.httpClient.post<ComercioGrupoModel>(this.baseUrl.getUrlApi() + 'comerciosgrupo', nuevoGrupo, { headers: this.headers });
  }

  editar_grupos(editarGrupo: ComercioGrupoModel) {
    return this.httpClient.get<ComercioGrupoModel>(this.baseUrl.getUrlApi() + 'comerciosgrupo/' + editarGrupo.comerciogrupoid, { headers: this.headers });
  }

  actualizar_grupos(actualizarGrupo: ComercioGrupoModel) {
    return this.httpClient.put<ComercioGrupoModel>(this.baseUrl.getUrlApi() + 'comerciosgrupo/' + actualizarGrupo.comerciogrupoid, actualizarGrupo, { headers: this.headers });
  }

  //::::::::::::::::::::::::::::::: CARGADO DE COMBOS ::::::::::::::::::::::::::::::://

  //Obtener Combo Comercio
  cboComercios_listar(): Observable<ComercioListarModel[]> {
    return this.httpClient.get<ComercioListarModel[]>(this.baseUrl.getUrlApi() + 'comercios', { headers: this.headers });
  }

  //Obtener Combo Roles
  cboRoles_listar(): Observable<Rol[]> {
    return this.httpClient.get<Rol[]>(this.baseUrl.getUrlApi() + 'roles_activos', { headers: this.headers });
  }

  //Obtener Combo Grupos
  cboGrupos_listar(): Observable<CmbGruposModel[]> {
    return this.httpClient.get<CmbGruposModel[]>(this.baseUrl.getUrlApi() + 'comerciosgrupo_activos', { headers: this.headers });
  }

  //Obtener Combo Comercio de grupo
  cmb_comercio_grupo_listar(idgrupo: number): Observable<ComercioListarModel[]> {
    return this.httpClient.get<ComercioListarModel[]>(this.baseUrl.getUrlApi() + 'comercios_porgrupo/' + idgrupo, { headers: this.headers });
  }

  obtenerCboComercioToolBar(userCom): Observable<ComercioToolBarModel[]> {
    return this.httpClient.get<ComercioToolBarModel[]>(this.baseUrl.getUrlApi() + 'comercios_byuser/' + userCom, { headers: this.headers });
  }

  obtenerCboComercioToolBar2(userCom): Observable<any[]> {
    return this.httpClient.get<any[]>(this.baseUrl.getUrlApi() + 'usuarios_auth/', { headers: this.headers });
  }

  //::::::::::::::::::::::::::: cambiar contraseña :::::::::::::::::::::::::::::://


  cambiarPassword(oldPasswordUser: string, newPasswordUser: string): void {
    var data = {
      UserPoolId: this.cognitoUtil.getUserPool().getUserPoolId(), // Your user pool id here
      ClientId: this.cognitoUtil.getUserPool().getClientId() // Your client id here
    };
    var userPool = new AmazonCognitoIdentity.CognitoUserPool(data);
    var cognitoUser = userPool.getCurrentUser();
    if (cognitoUser != null) {
      cognitoUser.getSession(function (err, session) {
        if (err) {
          //console.log(err)
          return;
        }
        cognitoUser.changePassword(oldPasswordUser, newPasswordUser, function (err, result) {
          if (err) {
            // callback.cognitoCallback(err['message'], null);
            Swal.fire(
              'Error de actualización',
              'Contraseña Incorrecta | N° de intentos excedidos',
              'warning'
            )
            return;
          }
          Swal.fire(
            'Contraseña actualizada',
            '',
            'success'
          )
          //callback.cognitoCallback(null, result);
        });
      });

    }
  }
}
