import { SharedService } from './../../../services/shared-service.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { JwtHelperService } from '@auth0/angular-jwt';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';
import { Router, NavigationEnd } from '@angular/router';
import { GetParametrosCognito } from 'app/services/getParametrosCognito.service';
import { UsuariosService } from 'app/main/apps/admin/usuarios/usuarios.service';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { AdminService } from 'app/main/apps/admin/admin.service';
import { ComercioListarModel } from 'app/main/models/comercioListarModel.model';
import { ConfiguracionUrl } from '../../../configuracionUrl';

//timer
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

import { filter } from 'rxjs/operators';
import { BankService } from 'app/main/apps/bank/services/bank.service';

declare var gtag;


@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy {
    //timer
    idleState = 'Not started.';
    timedOut = false;
    lastPing?: Date = null;

    initial: boolean = false

    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    helper: JwtHelperService;
    username: string = "";
    userCom: any = '';
    comercioIdCombo = 0;


    //barra comercio
    nombreComercio: any;
    cboComercio: ComercioListarModel[] = [];
    busquedaForm: FormGroup;
    busquedaComercioForm: FormGroup;
    nombreUsuario: any;
    rolUsuario: any;
    baseUrl: any;
    imgLogo: any;
    cmbMoneda: any[];
    paisId: any;

    customLoad: any;
    flagUniqueWallet: any;
    flagSendH2h: any;

    cboGrupos: any[];
    nombreGrupo: any;
    cboGrupo: ComercioListarModel[] = [];
    imgFlag: string = 'default';


    // Private
    private _unsubscribeAll: Subject<any>;
    //comercio_byuser/username
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        public router: Router,
        public authService: GetParametrosCognito,
        public _usuarioService: UsuariosService,
        private formBuilder: FormBuilder,
        private formBuilderComercio: FormBuilder,
        public _adminService: AdminService,
        public _sharedService: SharedService,
        private bankService: BankService,
        //timer
        private idle: Idle,
        private keepalive: Keepalive
    ) {

        const navEndEvents$ = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            );

        navEndEvents$.subscribe((event: NavigationEnd) => {
            gtag('config', 'G-5XMHQV4GW9', {
                'page_path': event.urlAfterRedirects
            });
        });

        this.initBuscador();

        this.obtenerComercio(this.userCom);

        //timer
        // sets an idle timeout of 5 seconds, for testing purposes.
        idle.setIdle(600);
        // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
        idle.setTimeout(30);
        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        idle.onIdleEnd.subscribe(() => this.idleState = '');
        idle.onTimeout.subscribe(() => {
            this.idleState = 'Tiempo Terminado!';
            this.timedOut = true;
            this.router.navigate(["pages/auth/logout"])
        });
        idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
        idle.onTimeoutWarning.subscribe((countdown) => this.idleState = 'La sesión terminará en  ' + countdown + ' segundos!');

        // sets the ping interval to 15 seconds
        keepalive.interval(15);

        keepalive.onPing.subscribe(() => this.lastPing = new Date());
        // if( this.idleState = 'Empieza'){
        //     if( this.idleState = 'Tiempo Terminado!'){
        //     }
        // }

        this.reset();


        this.baseUrl = new ConfiguracionUrl();
        this.helper = new JwtHelperService();
        let token = this.authService.getIdToken();
        const decodedToken = this.helper.decodeToken(token);
        if (decodedToken != null) {
            let userCom = `${decodedToken["cognito:username"]}`;
            this.userCom = userCom;
        }


        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon: 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon: 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon: 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];

        this.languages = [
            {
                id: 'es',
                title: 'Espanol',
                flag: 'es'
            }
            ,
            {
                id: 'en',
                title: 'English',
                flag: 'us'
            },
            {
                id: 'tr',
                title: 'Turkish',
                flag: 'tr'
            },
            {
                id: 'pe',
                title: 'Espanol',
                flag: 'pe'
            }

        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd)
        ).subscribe((event: any) => {
            if (String(event['url']).includes('/apps/banco')) {
                this.getBatchCountry()
            }
        })

        this.initial = true

        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, { id: this._translateService.currentLang });
    }

    //timer
    reset() {
        this.idle.watch();
        this.idleState = '';
        this.timedOut = false;
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
        //console.log(value);
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

    getBatchCountry() {
        this.bankService.getBatchByCountry(this.paisId)
            .pipe(
                map((result) => {
                    const parsedData = this.bankService.formatMerchantByCountryData(result)

                    this.cambioGrupoBatch({ ...parsedData.comercioData, imgLogo: 'logodefault.jpg' }, parsedData.nombreGrupo)
                    return result
                })
            ).subscribe()
    }

    logoutUser() {

        this.router.navigate(["pages/auth/logout"])
    }
    btnProfile() {

        this.router.navigate(["apps/admin/profile"])
    }
    initBuscador() {
        this.busquedaForm = this.formBuilder.group({
            comercio: new FormControl('', null)
        });

        this.busquedaComercioForm = this.formBuilderComercio.group({
            comercio: new FormControl('', null)
        });
    }

    //busqueda
    obtenerComercio(userCom) {
        this._adminService.obtenerCboComercioToolBar2(userCom)
            .subscribe(
                (data) => {
                    this.nombreGrupo = data['grupos'][0]['grupoName'];
                    this.cboGrupos = data['grupos'];
                    this.cboComercio = data['grupos'][0]['comercios'];
                    this.nombreUsuario = data['username'];
                    this.rolUsuario = data['rolname'];
                    localStorage.setItem("x-role", btoa(String(this.rolUsuario)));

                    if (this.initial) {
                        this._sharedService.grupoInitialSubject.next({
                            comercioData: this.cboComercio,
                            nombreGrupo: this.nombreGrupo,
                        })
                    }

                    this.cambioGrupo(this.cboComercio, this.nombreGrupo);
                });
    }

    cambioGrupo(comerciosData?, nombreGrupo?) {

        if (this.router.url.includes('/apps/banco')) return

        this.bankService.countryId = comerciosData[0]['paisId']

        localStorage.setItem('comercioId', '');
        this.nombreGrupo = nombreGrupo;
        this.imgLogo = this.baseUrl.getUrlApiImg() + comerciosData[0]['imglogo'] ? comerciosData[0]['imglogo'] : '';
        this.cboComercio = comerciosData;
        this.nombreComercio = this.cboComercio[0]['nombre'];
        this.comercioIdCombo = this.cboComercio[0]['comercioid'];
        this.paisId = comerciosData[0]['paisId']
        this.customLoad = comerciosData[0]['customLoad']
        this.flagUniqueWallet = comerciosData[0]['flagUniqueWallet']
        this.flagSendH2h = comerciosData[0]['flagSendH2h']
        this.cmbMoneda = comerciosData[0]['monedas'];
        this.imgFlag = comerciosData[0]['paisId'];
        localStorage.setItem('comercioId', JSON.stringify(this.comercioIdCombo));
        this._sharedService.changeMessageMoneda(this.paisId);
        this._sharedService.changeMessage(this.cmbMoneda);
        this._sharedService.changeMessageCfg({ customLoad: this.customLoad, flagUniqueWallet: this.flagUniqueWallet, flagSendH2h: this.flagSendH2h })
        console.log('paso 1', this.paisId, this.cmbMoneda);
    }

    cambioGrupoBatch(comerciosData?, nombreGrupo?) {
        // localStorage.setItem('comercioId', '');
        this.nombreGrupo = nombreGrupo;
        this.imgLogo = this.baseUrl.getUrlApiImg() + comerciosData[0]['imgLogo'] ? comerciosData[0]['imgLogo'] : '';
        this.cboComercio = comerciosData;
        this.nombreComercio = this.cboComercio[0]['nombre'];
        this.comercioIdCombo = this.cboComercio[0]['comercioid'];
        this.paisId = comerciosData[0]['paisId']
        this.bankService.countryId = comerciosData[0]['paisId']
        this.customLoad = comerciosData[0]['customLoad']
        this.flagUniqueWallet = comerciosData[0]['flagUniqueWallet']
        this.flagSendH2h = comerciosData[0]['flagSendH2h']
        this.cmbMoneda = comerciosData[0]['monedas'];
        this.imgFlag = comerciosData[0]['paisId'];
        // localStorage.setItem('comercioId', JSON.stringify(this.comercioIdCombo));
        this._sharedService.changeMessageMoneda(this.paisId);
        this._sharedService.changeMessage(this.cmbMoneda);
        this._sharedService.changeMessageCfg({ customLoad: this.customLoad, flagUniqueWallet: this.flagUniqueWallet, flagSendH2h: this.flagSendH2h })
    }

    cambioComercio(nombre, id, imglogo, cboMonedas, customLoad, flagUniqueWallet, _flagSendH2h) {
        localStorage.setItem('comercioId', '');
        this.nombreComercio = nombre;
        this.comercioIdCombo = id;
        this.imgLogo = imglogo ? imglogo : '';
        this.imgFlag = imglogo;
        this.paisId = imglogo;
        this.bankService.countryId = imglogo
        this._sharedService.changeMessageMoneda(imglogo);
        this._sharedService.changeMessage(cboMonedas);
        this._sharedService.changeMessageCfg({ customLoad: customLoad, flagUniqueWallet: flagUniqueWallet, flagSendH2h: _flagSendH2h })
        localStorage.setItem('comercioId', JSON.stringify(this.comercioIdCombo));
    }

}
