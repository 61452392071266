import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { IGetBankBatchPayload } from 'app/interfaces/get-bank-batch-payload.interface';
import { IGroupBankBatchPayload } from 'app/interfaces/group-bank-batch-request.interface';
import { UserLoginService } from 'app/main/pages/services/login-user.services';
import { SharedService } from 'app/services/shared-service.service';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { ConfiguracionUrl } from '../../../../configuracionUrl';

interface IBankBatch {
}

@Injectable({
  providedIn: 'root'
})

export class BankService {
  private batchBankUrl: string = `${environment.urlApiPayrollBack}api/batch/`
  private idtoken: string;

  private headers: HttpHeaders
  private headersBatch: HttpHeaders

  private baseUrl: ConfiguracionUrl

  private paisIdSubject: BehaviorSubject<string> = new BehaviorSubject<string>('')

  public paidId$: Observable<string> = this.paisIdSubject.asObservable()

  dataSubject: BehaviorSubject<IBankBatch> = new BehaviorSubject<IBankBatch>('')

  data$: Observable<IBankBatch> = this.dataSubject.asObservable()

  public set dataValue(value: IBankBatch) {
    this.dataSubject.next(value)
  }

  public set countryId(value: string) {
    this.paisIdSubject.next(value)
  }

  public get countryId(): string {
    return this.paisIdSubject.getValue()
  }

  public get dataValue(): IBankBatch {
    return this.dataSubject.getValue()
  }

  public get data(): Observable<IBankBatch> {
    return this.data$
  }

  constructor(
    private httpClient: HttpClient,
    private getParametroCognito: UserLoginService,
    private sharedService: SharedService,
  ) {
    this.baseUrl = new ConfiguracionUrl();
    this.idtoken = this.getParametroCognito.renovarToken();

    this.headersBatch = new HttpHeaders({
      'authorization': environment.authorization
    });

    this.headers = new HttpHeaders({
      'Content-type': 'application/json;charset=utf8',
      'Authorization': this.idtoken
    });
  }

  getBankBatch(params?: IGetBankBatchPayload): Observable<IBankBatch> {
    let param = new HttpParams()

    param = param.append('bankId', String(params.bankId))
    param = param.set("countryId", String(params.countryId))
    param = param.set("currency", String(params.currency))
    param = param.set("transferType", String(params.transferType))
    param = param.set("startDate", String(params.startDate))
    param = param.set("endDate", String(params.endDate))

    return this.httpClient.get<IBankBatch[]>(`${this.batchBankUrl}payrollBank`, { headers: this.headersBatch, params: param })
      .pipe(
        take(1),
        map((result: IBankBatch) => {
          this.dataValue = result
          return result
        }),
        catchError((err: Error) => {
          return throwError(err.message)
        })
      )
  }

  groupBankBatch(payload: IGroupBankBatchPayload, merchantId: string): Observable<any[]> {
    return this.httpClient.post<any>(`${environment.urlApiPayrollTrx}batch-grouping/${merchantId}`, payload, { headers: this.headersBatch })
      .pipe(
        switchMap((result) => this.downloadBatchFile(result.filePath)),
        catchError((err: Error) => {
          return throwError(err.message)
        })
      )
  }

  downloadBatchFile(fileName: string): Observable<any> {
    return this.httpClient.get(`${environment.urlApiPayrollTrx}api/bank-file/download?filename=${fileName}`, {
      responseType: 'text'
    })
      .pipe(
        tap((result) => {
          const blob = new Blob([result as string], { type: 'text/plain' });

          const url = window.URL.createObjectURL(blob);
          const element = document.createElement('a');

          element.href = url;
          element.download = `${fileName}`;

          document.body.appendChild(element);

          element.click();

          document.body.removeChild(element);

          window.URL.revokeObjectURL(url);
        }),
      )
  }

  getBatchByCountry(countryId: string): Observable<any> {
    return this.httpClient.get(`${environment.urlApiPayrollBack}merchants/batch-merchant/${countryId}`, { headers: this.headersBatch })
      .pipe(
        take(1),
        map((result: any) => {
          localStorage.setItem('comercioId', String(result.comercioid));
          return result
        }),
        catchError((err: Error) => {
          return throwError(err)
        })
      )
  }

  formatMerchantByCountryData(data: any) {
    return {
      comercioData: {
        "comercioid": data.comercioid,
        "nombre": data.name,
        "imglogo": " ",
        "paisId": data.countryId,
        "customLoad": data.customLoad,
        "flagUniqueWallet": data.flgUniqueWallet,
        "flagSendH2h": data.h2hSendFlag,
        "monedas": data.currencyList,
      },
      nombreGrupo: data.name
    }
  }
}
