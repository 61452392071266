<div class="navbar-header" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

    <div class="logo">
        <img class="logo-icon" src="assets/images/logos/MonnetNav.png">
        <!-- <span class="logo-text">PAYOUT</span> -->
    </div>

    <button mat-icon-button class="toggle-sidebar-folded"
            (click)="toggleSidebarFolded()" fxHide.lt-lg>
        <mat-icon>menu</mat-icon>
    </button>

    <button mat-icon-button class="toggle-sidebar-opened"
            (click)="toggleSidebarOpened()" fxHide.gt-md>
        <mat-icon>arrow_back</mat-icon>
    </button>

</div>

<div class="navbar-content" fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}"
     [ngClass]="fuseConfig.layout.navbar.primaryBackground">
    <fuse-navigation layout="vertical"></fuse-navigation>
</div>

<div class="navbar-footer" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

    <div class="langContainer">
        <table class="table" margin=auto>
            <tr class="langRow">    
                <img class="planetIcon" src="assets/icons/others/planet.png">
                <td class="langColumm"><button id="esBtn" class="langBtn" [ngClass]="language=='es' ? 'langBtnSelected' : 'langBtn'" (click)="setLanguage('es')">ES</button></td>
                <td>/</td>
                <td class="langColumm"><button id='enBtn' class="langBtn" [ngClass]="language=='en' ? 'langBtnSelected' : 'langBtn'" (click)="setLanguage('en')">EN</button></td>
            </tr>
        </table>
    </div>

</div>