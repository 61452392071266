import { Observable } from 'rxjs';
import { Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CognitoService } from './cognito.service';
import { UserLoginService } from './main/pages/services/login-user.services';
import { ConfiguracionUrl } from './configuracionUrl';
import { Injectable } from '@angular/core';

//import { map } from 'rxjs/operators';
//import { HttpClient, RequestOptions } from '@angular/common/http';

export interface CognitoCallback {
  cognitoCallback(message: string, result: any): void;
  handleMFAStep?(challengeName: string, challengeParameters: ChallengeParameters, callback: (confirmationCode: string) => any): void;
}

export interface ChallengeParameters {
  CODE_DELIVERY_DELIVERY_MEDIUM: string;
  CODE_DELIVERY_DESTINATION: string;
}

@Injectable({
  providedIn: 'root'
})

export class InicioService {
  baseUrl: ConfiguracionUrl;
  urlApi: string;
  tipoUsuario: string;
  headers: any;
  option: any;
  perfil: any;
  datagen: any;
  idtoken: string;
  idDevice: string;
  tokenAcess: string;

  constructor(private httpClient: HttpClient,
    getParametroCognito: UserLoginService,
    @Inject(CognitoService) public cognitoUtil: CognitoService) {
    this.baseUrl = new ConfiguracionUrl();
    this.idtoken = getParametroCognito.renovarToken();
    this.headers = new HttpHeaders({
      'Content-type': 'application/json;charset=utf8',
      'Authorization': this.idtoken
      // 'DeviceKey' : this.idDevice,
      // 'AccessToken' : this.tokenAcess
    });
    //   this.option = new RequestOptions({headers:this.headers});
  }

  obtener_inicio(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.baseUrl.getUrlApi() + 'pais_activos',{ headers: this.headers });
  }

}
